import { ReactElement, useCallback } from "react";
import { useRecoilValue } from "recoil";
import "./App.css";
import { balanceAtom } from "./atom/balanceAtom";
import { userAtom } from "./atom/userAtom";
import { useInvoice } from "./hooks/useInvoice";
import { useWebApp } from "./providers/WebApp/useWebApp";

export function App(): ReactElement {
  const { loaded } = useWebApp();
  const { createInvoice, decodeUrl } = useInvoice();
  const user = useRecoilValue(userAtom);
  const balance = useRecoilValue(balanceAtom);

  const doInvoice = useCallback(async (): Promise<void> => {
    const response = await fetch(
      "https://api.minisovellus.indapublic.ru/invoice/generate",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    const invoiceUrl = json.invoiceLink;
    const { slug, url } = decodeUrl(invoiceUrl);
    createInvoice(url, slug, (status: string) => {
      if ("paid" !== status) {
        return;
      }
    });
  }, []);

  if (!loaded) {
    return (
      <span
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Ждите...
      </span>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {user && (
        <span>
          Привет, <strong>{`${user.first_name} ${user.last_name}`}</strong>
        </span>
      )}
      <span style={{ height: 30 }} />
      {false && (
        <button
          onClick={() => {
            let tg = window.Telegram.WebApp; //получаем объект webapp телеграма

            tg.expand(); //расширяем на все окно

            tg.MainButton.text = "Changed Text"; //изменяем текст кнопки
            tg.MainButton.setText("Changed Text1"); //изменяем текст кнопки иначе
            tg.MainButton.textColor = "#F55353"; //изменяем цвет текста кнопки
            tg.MainButton.color = "#143F6B"; //изменяем цвет бэкграунда кнопки
            tg.MainButton.setParams({ color: "#143F6B" }); //так изменяются все параметры

            tg.MainButton.hide();
            // tg.MainButton.show();
          }}
        >
          Press me dude
        </button>
      )}
      <div
        style={{
          flexDirection: "row",
        }}
      >
        <span>У тебя в карманах </span>
        <strong>{balance}</strong>
        <span> рублей</span>
      </div>
      <span style={{ height: 30 }} />
      <button onClick={doInvoice}>Закинуть баблишка</button>
    </div>
  );
}
