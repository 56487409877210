import { useState } from "react";
import { useRecoilState } from "recoil";
import { useInterval, useToggle } from "usehooks-ts";
import { userAtom } from "../../atom/userAtom";

const INTERVAL = 500;

export function useWebApp() {
  const [loaded, toggle] = useToggle();
  const [webApp, setWebApp] = useState<WebApp | null>(null);
  const [, setUser] = useRecoilState(userAtom);

  useInterval(
    () => {
      if (window.Telegram?.WebApp) {
        setWebApp(window.Telegram.WebApp);
        setUser(window.Telegram.WebApp.initDataUnsafe.user as WebAppUser);
        toggle();
      }
    },
    loaded ? null : INTERVAL
  );

  return { loaded, webApp };
}
