import { useRecoilState } from "recoil";
import { invoicesAtom } from "../atom/invoicesAtom";

export function useInvoice() {
  const [invoices, setInvoices] = useRecoilState(invoicesAtom);

  function createInvoice(
    url: string,
    slug: string,
    callback: CallableFunction
  ) {
    // if (!versionAtLeast('6.1')) {
    //   console.error('[Telegram.WebApp] Method openInvoice is not supported in version ' + webAppVersion);
    //   throw Error('WebAppMethodUnsupported');
    // }

    // if (webAppInvoices[slug]) {
    //   console.error('[Telegram.WebApp] Invoice is already opened');
    //   throw Error('WebAppInvoiceOpened');
    // }

    const temp = invoices;
    temp.set(slug, {
      url,
      callback,
    });
    setInvoices(new Map(temp));

    (window.Telegram as any).WebView.postEvent("web_app_open_invoice", false, {
      slug: slug,
    });
  }

  function decodeUrl(url: string) {
    let match;
    let slug;
    const a = document.createElement("a");
    a.href = url;

    if (
      (a.protocol !== "http:" && a.protocol !== "https:") ||
      a.hostname !== "t.me" ||
      !(match = a.pathname.match(/^\/(\$|invoice\/)([A-Za-z0-9\-_=]+)$/)) ||
      !(slug = match[2])
    ) {
      console.error("[Telegram.WebApp] Invoice url is invalid", url);

      throw Error("WebAppInvoiceUrlInvalid");
    }

    return { url, slug };
  }

  return { createInvoice, decodeUrl };
}
