import { atom } from "recoil";

type Invoice = {
  url: string;
  callback: CallableFunction;
};

export const invoicesAtom = atom({
  key: "invoicesAtom",
  default: new Map<string, Invoice>(),
});
