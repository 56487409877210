import { ReactElement, useEffect } from "react";
import { WebAppProps } from "./types";
import { useWebApp } from "./useWebApp";

export function WebApp(props: WebAppProps): ReactElement<WebAppProps> | null {
  const { children } = props;
  const { loaded } = useWebApp();

  useEffect((): void => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://telegram.org/js/telegram-web-app.js";
    document.head.appendChild(script);
  }, []);

  if (!loaded) {
    return (
      <span
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Загрузка...
      </span>
    );
  }

  return <>{children}</>;
}
